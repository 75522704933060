import React, { FC } from 'react';

import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';
import { IPropsContactLinks } from './models';

const ContactLinks: FC<IPropsContactLinks> = ({ value }) => {
  const isPhone = /^(?:[0-9 ]+$)/.test(value);
  const isEmail = value.indexOf('@') > 0;
  const clearValue = isPhone || isEmail ? value.replace(/<\/?[^>]+(>|$)/g, '') : value;

  return isPhone ? (
    <a href={`tel:${clearValue}`}>{clearValue}</a>
  ) : isEmail ? (
    <a href={`mailto:${clearValue}`}>{clearValue}</a>
  ) : (
    <DangerouslySetInnerHtml html={value} />
  );
};

export default ContactLinks;
