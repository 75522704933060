import React, { FC } from 'react';

import GatsbyImage from 'common/GatsbyImage';
import ContactLinks from 'common/ContactLinks';

import { IPropsContactUsBlock } from './models';

const ContactUsBlock: FC<IPropsContactUsBlock> = ({ content }) => (
  <div data-test="ContactUsBlock">
    {content.map(({ properties: { contactTitle, contactValue, imageIcon } }) => (
      <div className="contact-us-page__info-list-item" key={contactValue}>
        {imageIcon?.[0]?.properties?.accordionItemImage ? (
          <GatsbyImage
            alt={imageIcon?.[0].properties?.accordionItemImageAltText || ''}
            image={imageIcon[0].properties.accordionItemImage}
            className="contact-us-page__image"
          />
        ) : null}

        {contactTitle ? (
          <span className="contact-us-page__info-list-title">{contactTitle}</span>
        ) : null}
        <ContactLinks value={contactValue} />
      </div>
    ))}
  </div>
);

export default ContactUsBlock;
